import React from 'react';

import AppleStoreLogo from 'assets/icons/appStore_badge.svg';
import styled from 'styled-components';

import { StoresLinksWY } from 'utils/constants/externalLinks';
import { smMobile } from 'styles/breakpoints';

const StyledLink = styled.a``;
const AppleStoreBadge = styled(AppleStoreLogo)`
  width-min: 163.5px;
  height-min: 47.3px;
  width: 100%;
  height: 100%;
  @media ${smMobile} {
    width: 131px;
    height: 38px;
  }
`;
const AppleStoreLinkBtnWY = ({ ...props }) => (
  <StyledLink href={StoresLinksWY.AppleStore} target="_blank" {...props}>
    <AppleStoreBadge />
  </StyledLink>
);

export default AppleStoreLinkBtnWY;
